import React from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import { ShowMore } from '../../elements/ShowMore/ShowMore';
import svgSprite from '../../../../../assets/images/sprites/sprites.svg';
import styles from './SEOFooter.module.scss';

export const SEOFooter = ({ headline, full }) => {
  const FOOTER_REGEX = /<p>\s+</;

  if ((full && FOOTER_REGEX.test(full)) || !full) {
    return null;
  }
  return (
    <div className={styles.SEOFooter}>
      <ShowMore contentClass={styles.SEOFooterContent}>
        <div className={styles.SEOFooterLogo}>
          <img src={`${svgSprite}#logoGrey`} alt="asambeauty" width="164" height="29" />
        </div>
        <div className={styles.SEOFooterWrap}>
          {headline && <div className="SEOFooterHeader SEOFooterCenter">{headline}</div>}
          {full && !FOOTER_REGEX.test(full) && <InnerHTML html={full} className="SEOFooterFullWidth" />}
        </div>
      </ShowMore>
    </div>
  );
};
