import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEOStructuredData = props => {
  let JSONdata;

  if (typeof props.JSONdata === 'object') {
    JSONdata = JSON.stringify(props.JSONdata).replace(/(<([^>]+)>)/gi, '');
  } else {
    const execResult = new RegExp(
      /\s*<script\s+type="application\/ld\+json"\s*>\s*([\w\W]*?)\s*<\/\s*script\s*>\s*/,
      'gm'
    ).exec(props.JSONdata);

    JSONdata = (execResult?.length > 1 && execResult.slice(1).join('')) || props.JSONdata;
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSONdata}</script>
    </Helmet>
  );
};

export default SEOStructuredData;
