import { Helmet } from 'react-helmet-async';
import React from 'react';

export const HomeMeta = ({ externalSystems, baseUrl, contentfulPage, pinterestMetaContent, pinterestMetaActive }) => {
  const current = baseUrl || null;
  const storeBase = current ? new URL(current) : null;
  const base = storeBase.origin || null;

  return (
    <Helmet>
      {contentfulPage?.contentData?.meta_title && <title>{contentfulPage.contentData.meta_title}</title>}
      {contentfulPage?.contentData?.meta_title && (
        <meta property="og:title" content={contentfulPage.contentData.meta_title} />
      )}
      {contentfulPage?.contentData?.meta_description && (
        <meta name="description" content={contentfulPage.contentData.meta_description} />
      )}
      {contentfulPage?.contentData?.meta_description && (
        <meta property="og:description" content={contentfulPage.contentData.meta_description} />
      )}
      {current && <meta property="og:url" content={current} />}
      {contentfulPage.contentData.index ? (
        <meta name="robots" content={contentfulPage.contentData.index} />
      ) : (
        <meta name="robots" content="NOINDEX, NOFOLLOW, NOARCHIVE" />
      )}
      {contentfulPage.contentData.canonicalLink && (
        <link rel="canonical" href={`${contentfulPage.contentData.canonicalLink}`} />
      )}
      {!contentfulPage.contentData.canonicalLink && current && <link rel="canonical" href={current} />}
      {externalSystems.map(system => {
        const suffix = `/${system.suffix}/`.replace(/\/\/\/|\/\//, '/');
        return (
          <link key={`store-${system.locale}`} rel="alternate" hreflang={system.locale} href={`${base}${suffix}`} />
        );
      })}
      {pinterestMetaActive ? <meta name="p:domain_verify" content={pinterestMetaContent} /> : null}
    </Helmet>
  );
};
