import React, { useEffect } from 'react';
import { UrlQuery } from '@deity/falcon-data';
import { CmsPageQuery } from '../../elements/Cms/CmsQuery';
import { SEOFooter } from '../../sections/SEOFooter/SEOFooter';
import Container from '../../layout/Container/Container';
import { GoogleTagManager4Pageview } from '../../elements/Tracking/GoogleTagManager';
import loadable from '../../../../../components/loadable';
import { Store } from '../../elements/Store/Store';
import { BodyElementAttribute } from '../../helpers/BodyElementAttribute/BodyElementAttribute';
import { HomeMeta } from './HomeMeta';
import SEOStructuredData from './components/SEOStructuredData';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const Home = () => {
  useEffect(() => {
    BodyElementAttribute({
      key: 'data-page-type',
      value: 'homepage',
      action: 'set'
    });

    return () => {
      BodyElementAttribute({
        key: 'data-page-type',
        action: 'remove'
      });
    };
  }, []);
  return (
    <UrlQuery variables={{ path: '/' }}>
      {data => (
        <CmsPageQuery variables={{ id: data.data.url.id }}>
          {({ data: { contentfulPage }, loading }) => {
            if (loading) {
              return (
                <Container>
                  <div className="PageLoaderContainer">
                    <div className={['PageLoader', 'PageLoaderDesktop', 'HideMobile'].join(' ')} />
                    <div className={['PageLoader', 'PageLoaderMobile', 'ShowMobile'].join(' ')} />
                  </div>
                </Container>
              );
            }
            if (contentfulPage && contentfulPage.contentData) {
              return (
                <>
                  <Store>
                    {({ getStoreConfigJson, getStoreConfigValue, getStoreConfigFlag }) => (
                      <HomeMeta
                        contentfulPage={contentfulPage}
                        externalSystems={Object.values(
                          getStoreConfigJson('asam_store.language_switch.external_systems')
                        )}
                        baseUrl={getStoreConfigValue('web.secure.base_url')}
                        pinterestMetaActive={getStoreConfigFlag('asam_tracking.pinterest.active')}
                        pinterestMetaContent={getStoreConfigValue('asam_tracking.pinterest.meta_tag_content')}
                      />
                    )}
                  </Store>
                  {contentfulPage.contentData.modules.map((item, index) => (
                    <Row
                      key={`Row -'${item?.fields?.name || item?.fields?.internal_description || ''}'`}
                      item={item.fields}
                      contentType={item.sys.contentType.sys.id}
                      row={index}
                    />
                  ))}
                  <SEOFooter
                    headline={contentfulPage?.contentData?.seo_footer_headline}
                    full={contentfulPage?.contentData?.seo_footer_fullwidth}
                  />
                  <SEOStructuredData JSONdata={contentfulPage?.contentData?.ld_json} />
                  <GoogleTagManager4Pageview
                    dataLayerData={{ page_type: 'homepage', page_title: contentfulPage?.contentData?.meta_title }}
                  />
                </>
              );
            }
          }}
        </CmsPageQuery>
      )}
    </UrlQuery>
  );
};

export default Home;
