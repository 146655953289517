import React, { useRef } from 'react';
import { PropTypes } from 'prop-types';
import { Toggle } from 'react-powerplug';
import { SlideDown } from 'react-slidedown';
import { T } from '@deity/falcon-i18n';
import Container from '../../layout/Container/Container';
import styles from './ShowMore.module.scss';

export const ShowMore = props => {
  const { variant, contentClass, ...restProps } = props;
  const additionalClasses = [];

  if (variant === 'white') {
    additionalClasses.push(styles.ShowMoreWhite);
  }

  const elementRef = useRef(null);

  const scrollDown = e => {
    const scrollToElement = ref => window.scrollTo(0, ref.current.offsetTop);
    const handleScroll = () => scrollToElement(elementRef);

    return e.currentTarget.classList.contains(styles.Active) ? handleScroll(e) : null;
  };

  return (
    <Toggle initial={false} {...restProps}>
      {({ on, toggle }) => {
        const linkVariantClass = on ? 'LinkWithIconTop' : 'LinkWithIconBottom';
        const blockContent = (
          <div className={[styles.ShowMoreContent, contentClass, ...additionalClasses].join(' ')}>{props.children}</div>
        );

        return (
          <React.Fragment>
            <Container>
              <div className={[styles.ShowMoreContainer, on ? styles.Active : null].join(' ')}>
                <SlideDown>{on ? blockContent : blockContent}</SlideDown>
              </div>
              <div
                className={[styles.ShowMoreLink, on ? styles.Active : null].join(' ')}
                onClick={e => {
                  toggle();
                  scrollDown(e);
                }}
                aria-hidden
                ref={elementRef}
              >
                <span
                  className={`Link LinkPurple LinkNoUnderline LinkWithIcon LinkWithMediumWeight ${linkVariantClass} after-icon-chevron-down`}
                >
                  {on ? <T id="showLess" /> : <T id="showMore" />}
                </span>
              </div>
            </Container>
          </React.Fragment>
        );
      }}
    </Toggle>
  );
};

ShowMore.propTypes = {
  /** show more gradient color variant */
  variant: PropTypes.oneOf(['white']),
  /** show more content class name */
  contentClass: PropTypes.string
};
